import {
  GET_DEALS_URL_UNIFIED,
  GET_TABLE_DATA_BR_QUERY_KEY,
  GET_TABLE_DATA_ZA_QUERY_KEY
} from '@constants'
import { DealPagination, IGetDealsResponse, IGetTableData, IGetTableDataV2 } from '@interfaces'
import { useQuery } from '@tanstack/react-query'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { AxiosError, AxiosResponse } from 'axios'
import {
  TTableItem,
  tableDataNormalizer
} from 'pages/Deals/List/DealsTable/shared/tableDataNormalizer'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

/** For BR */
export const useGetTableData = ({
  page,
  pageSize,
  offerStatus,
  selectedChain,
  vendorName,
  advertStartDate,
  advertEndDate,
  buyInStartDate,
  buyInEndDate,
  offerType,
  mechanic,
  campaignProduct,
  category
}: IGetTableData) => {
  const auth = useAuthenticationService()
  const api = useApiService()

  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    requestTraceId: generateUUID()
  }

  const getTableData = async (): Promise<{
    content: TTableItem[]
    pagination: DealPagination
    requestTraceId: string
  }> => {
    const kamEmail = auth.getUserEmailB2C().toLocaleLowerCase()
    const url = GET_DEALS_URL_UNIFIED

    /* istanbul ignore next */
    const chains = selectedChain === 'all' ? undefined : selectedChain

    const response: AxiosResponse<IGetDealsResponse> = await api.get({
      url,
      config: {
        headers,
        params: {
          chains,
          kamEmail,
          page,
          pageSize,
          offerStatus,
          vendorName,
          advertStartDate,
          advertEndDate,
          buyInStartDate,
          buyInEndDate,
          offerType,
          mechanic,
          campaignProduct,
          category
        }
      }
    })

    const formattedData = tableDataNormalizer(response.data.pagedDeals.content)

    return {
      content: formattedData,
      pagination: response.data.pagedDeals.pagination,
      requestTraceId: headers.requestTraceId
    }
  }

  return useQuery<
    {
      content: TTableItem[]
      pagination: DealPagination
      requestTraceId?: string
    },
    AxiosError
  >(
    [
      GET_TABLE_DATA_BR_QUERY_KEY,
      {
        page,
        pageSize,
        offerStatus,
        selectedChain,
        vendorName,
        advertStartDate,
        advertEndDate,
        buyInStartDate,
        buyInEndDate,
        offerType,
        mechanic,
        campaignProduct,
        category
      }
    ],
    getTableData,
    {
      onError: (error) => {
        ;(error as AxiosError & { requestTraceId?: string }).requestTraceId = headers.requestTraceId
      }
    }
  )
}

/** For ZA */
export const useGetTableDataV2 = ({ page, pageSize, selectedChain }: IGetTableDataV2) => {
  const auth = useAuthenticationService()
  const api = useApiService()

  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    requestTraceId: generateUUID()
  }

  const getTableData = async (): Promise<any> => {
    const kamEmail = auth.getUserEmailB2C().toLocaleLowerCase()
    const url = `api/v1/link-deals/deals/v2/kam`

    /* istanbul ignore next */
    const chain = selectedChain === 'all' ? undefined : selectedChain

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: AxiosResponse<any> = await api.get({
      url,
      config: {
        headers,
        params: {
          chain,
          kamEmail,
          page,
          pageSize
        }
      }
    })

    return response.data
  }

  const { data, isLoading, refetch } = useQuery(
    [GET_TABLE_DATA_ZA_QUERY_KEY, page, pageSize, selectedChain],
    getTableData
  )

  return {
    data,
    isLoading,
    refetch
  }
}
