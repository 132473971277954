import { useAuthenticationService } from 'admin-portal-shared-services'
import { AxiosResponse } from 'axios'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

export const useDownloadList = () => {
  const auth = useAuthenticationService()
  const api = useApiService()

  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    requestTraceId: generateUUID()
  }

  const downloadList = async (fileType: string) => {
    const kamEmail = auth.getUserEmailB2C().toLocaleLowerCase()
    const url = `api/v1/link-deals/deals/kam/download?kamEmail=${kamEmail}&fileType=${fileType}`

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: AxiosResponse<any> = await api.get({
      url,
      config: { headers }
    })

    return Promise.resolve(response.data)
  }
  return {
    downloadList
  }
}
