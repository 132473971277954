import { GET_DEALS_URL, GET_STORE_LIST_QUERY_KEY } from '@constants'
import { IGetStoreList, IGetStoreListResponse, StoreListPagination, TStoreList } from '@interfaces'
import { useQuery } from '@tanstack/react-query'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { AxiosError, AxiosResponse } from 'axios'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

export const useGetStoreList = ({ page, pageSize, dealId, search }: IGetStoreList) => {
  const auth = useAuthenticationService()
  const api = useApiService()

  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    requestTraceId: generateUUID()
  }

  const getStoreList = async (): Promise<{
    content: TStoreList[]
    pagination: StoreListPagination
  }> => {
    const url = `${GET_DEALS_URL}/${dealId}`

    const response: AxiosResponse<IGetStoreListResponse> = await api.get({
      url,
      config: {
        headers,
        params: {
          page,
          pageSize,
          ...(search !== '' && { search })
        }
      }
    })

    return {
      content: response.data.content,
      pagination: response.data.pagination
    }
  }

  return useQuery<
    {
      content: TStoreList[]
      pagination: StoreListPagination
    },
    AxiosError
  >([GET_STORE_LIST_QUERY_KEY, { page, pageSize, dealId, search }], getStoreList)
}
