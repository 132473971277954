import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

interface IFilterContext {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  toggleDropdown: () => void;
  closeDropdown: () => void;
  selectedItem: string | null;
  setSelectedItem: (item: string | null) => void;
  filters: { [key: string]: string[] };
  setFilters: (filters: { [key: string]: string[] }) => void;
  addFilter: (category: string, item: string) => void;
  removeFilter: (category: string, item: string) => void;
  getFilterCount: (category: string) => number;
  getTotalFilterCount: () => number;
  isItemSelected: (category: string, item: string) => boolean;
  resetFilters: () => void;
  handleApplyFilters: (filters: { [key: string]: string[] }) => void;
  appliedFilters: { [key: string]: string[] };
  data: any[];
  setData: (data: any[]) => void;
  handleClearAllFilters: () => void;
}

const FilterContext = createContext({} as IFilterContext);

const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string[] }>({});
  const [data, setData] = useState<any[]>([]);

  /* istanbul ignore next */
  useEffect(() => {
    if (data.length > 0 && !selectedItem) {
      setSelectedItem(data[0].attributeName);
    }
  }, [data, selectedItem]);

  const toggleDropdown = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const addFilter = useCallback((category: string, item: string) => {
    setFilters(prevFilters => {
      const newFilters = JSON.parse(JSON.stringify(prevFilters));
      if (!newFilters[category]) {
        newFilters[category] = [];
      }
      if (!newFilters[category].includes(item)) {
        newFilters[category].push(item);
      }
      return newFilters;
    });
  }, []);

  const removeFilter = useCallback((category: string, item: string) => {
    setFilters(prevFilters => {
      const newFilters = JSON.parse(JSON.stringify(prevFilters));
      if (newFilters[category]) {
        newFilters[category] = newFilters[category].filter(filterItem => filterItem !== item);
        if (newFilters[category].length === 0) {
          delete newFilters[category];
        }
      }
      return newFilters;
    });
  }, []);

  const getFilterCount = useCallback((category: string) => {
    return filters[category]?.length || 0;
  }, [filters]);

  const getTotalFilterCount = useCallback(() => {
    return Object.values(filters).reduce((acc, filterArray) => acc + filterArray.length, 0);
  }, [filters]);

  const isItemSelected = useCallback((category: string, item: string) => {
    return filters[category]?.includes(item) || false;
  }, [filters]);

  /* istanbul ignore next */
  const resetFilters = useCallback(() => {
    setFilters({ ...appliedFilters });
  }, [appliedFilters]);

  /* istanbul ignore next */
  const handleApplyFilters = useCallback((filters: { [key: string]: string[] }) => {
    setAppliedFilters({ ...filters });
  }, []);

  const handleClearAllFilters = () => {
    setFilters({});
  };

  return (
    <FilterContext.Provider
      value={{
        isOpen,
        setIsOpen,
        toggleDropdown,
        closeDropdown,
        selectedItem,
        setSelectedItem,
        filters,
        setFilters,
        addFilter,
        removeFilter,
        getFilterCount,
        getTotalFilterCount,
        isItemSelected,
        resetFilters,
        handleApplyFilters,
        appliedFilters,
        data,
        setData,
        handleClearAllFilters
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

const useFilter = () => {
  return useContext(FilterContext);
};

export { FilterContext, FilterProvider, useFilter };
