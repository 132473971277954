/* eslint-disable react/no-unused-prop-types */
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useEnvService } from 'admin-portal-shared-services'

export type EnvConfig = {
  SEGMENT_KEY: string
  baseUrl: string
  cmsBaseUrl: string
  serviceBaseUrl: string
}

export const EnvironmentContext = createContext<EnvConfig>({
  baseUrl: '',
  cmsBaseUrl: '',
  SEGMENT_KEY: '',
  serviceBaseUrl: ''
})

export const PROD_BASE_URL = 'https://one.bees-platform.com'
export const UAT_BASE_URL = 'https://one-uat.bees-platform.dev'
export const SIT_BASE_URL = 'https://one-sit.bees-platform.dev'

export const PROD_CMS_BASE_URL = 'https://cms-services.bees-platform.com'
export const UAT_CMS_BASE_URL = 'https://cms-services-uat.bees-platform.dev'
export const SIT_CMS_BASE_URL = 'https://cms-services-sit.bees-platform.dev'

export const PROD_SERVICE_BASE_URL = 'https://services.bees-platform.com'
export const UAT_SERVICE_BASE_URL = 'https://services-uat.bees-platform.dev'
export const SIT_SERVICE_BASE_URL = 'https://services-sit.bees-platform.dev'

export function EnvironmentProvider({ children, SEGMENT_KEY }: PropsWithChildren<EnvConfig>) {
  const [baseUrl, setBaseUrl] = useState('')
  const [cmsBaseUrl, setCmsBaseUrl] = useState('')
  const [serviceBaseUrl, setServiceBaseUrl] = useState('')
  const environment = useEnvService().getEnv()

  useEffect(() => {
    let innerBaseUrl = ''
    let innerCmsBaseUrl = ''
    let innerServiceBaseUrl = ''

    switch (environment) {
      case 'PROD':
        innerBaseUrl = PROD_BASE_URL
        innerCmsBaseUrl = PROD_CMS_BASE_URL
        innerServiceBaseUrl = PROD_SERVICE_BASE_URL
        break
      case 'UAT':
        innerBaseUrl = UAT_BASE_URL
        innerCmsBaseUrl = UAT_CMS_BASE_URL
        innerServiceBaseUrl = UAT_SERVICE_BASE_URL
        break
      default:
        innerBaseUrl = SIT_BASE_URL
        innerCmsBaseUrl = SIT_CMS_BASE_URL
        innerServiceBaseUrl = SIT_SERVICE_BASE_URL
        break
    }

    setBaseUrl(innerBaseUrl)
    setCmsBaseUrl(innerCmsBaseUrl)
    setServiceBaseUrl(innerServiceBaseUrl)
  }, [environment])

  const value = useMemo(
    () => ({ baseUrl, cmsBaseUrl, SEGMENT_KEY, serviceBaseUrl }),
    [baseUrl, cmsBaseUrl, SEGMENT_KEY, serviceBaseUrl]
  )

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
}
