import { PropsWithChildren } from 'react'
import {
  ChainProvider,
  ContainerDataProvider,
  EnvConfig,
  EnvironmentProvider,
  FilterProvider,
  I18nProvider,
  ThemeProvider
} from '..'

export function GlobalProvider({ env, children }: PropsWithChildren<{ env: EnvConfig }>) {
  return (
    <ThemeProvider>
      <EnvironmentProvider {...env}>
        <I18nProvider>
          <ContainerDataProvider>
            <FilterProvider>
              <ChainProvider>{children}</ChainProvider>
            </FilterProvider>
          </ContainerDataProvider>
        </I18nProvider>
      </EnvironmentProvider>
    </ThemeProvider>
  )
}
