import { useLocalStorage } from 'admin-portal-shared-services'
import { useState } from 'react'
import { localStorageKeys } from '../../constants'
import { UsePagination } from './usePagination.types'

export const usePagination: UsePagination = (options) => {
  const { initialRowsPerPage = 10, pageName } = options
  const { localStoragePageSize } = localStorageKeys(pageName)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useLocalStorage(localStoragePageSize, initialRowsPerPage)

  const onChangePagination = (onChangedPage: number, onChangedPageSize: number) => {
    setPage(onChangedPage - 1)
    setPageSize(onChangedPageSize)
  }

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    onChangePagination
  }
}
