/* eslint-disable @typescript-eslint/no-explicit-any */
import { useIs3pUser } from '@hooks'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { AxiosResponse } from 'axios'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

export const useDownloadTemplate = () => {
  const auth = useAuthenticationService()
  const api = useApiService()
  const { is3pUser } = useIs3pUser()

  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    requestTraceId: generateUUID(),
    email: auth.getUserEmailB2C().toLocaleLowerCase(),
    vendor: is3pUser ? 'LOREAL' : 'ABI',
    version: 2
  }

  const handleRequest = async (url: string) => {
    const response = await api.get({
      url,
      config: { headers }
    })
    return response.data
  }

  const requestTemplateGeneration = async () => {
    return handleRequest(`api/v1/link-deals/deals/generate/template`)
  }

  const getTemplate = async (templateId: string, timeout: number = 3000) => {
    const response: any = await Promise.race([
      new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Timeout exceeded')), timeout)
      }),
      Promise.resolve(
        handleRequest(`api/v1/link-deals/deals/template/${templateId}`) as unknown as AxiosResponse<
          any,
          any
        >
      )
    ])

    return response
  }

  return {
    requestTemplateGeneration,
    getTemplate
  }
}
