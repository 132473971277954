import { GET_DEALS_FILTER_QUERY_KEY, GET_DEALS_FILTER_URL } from '@constants'
import { useQuery } from '@tanstack/react-query'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { useApiService } from 'api/api'
import { AxiosError } from 'axios'
import { generateUUID } from 'service'

type TGetFiltersParams = {
  attributeNames: string
}

export const useGetFilters = (params: TGetFiltersParams) => {
  const auth = useAuthenticationService()
  const api = useApiService()

  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    requestTraceId: generateUUID(),
  }

  const getQueryKey = () => {
    return [GET_DEALS_FILTER_QUERY_KEY, params]
  }

  const getFilters = async (): Promise<{
    content: []
    requestTraceId: string
  }> => {
    const response = await api.get<any>({
      url: GET_DEALS_FILTER_URL,
      config: {
        headers,
        params: {
          attributeNames: params.attributeNames
        }
      }
    })

    return {
      content: response.data,
      requestTraceId: headers.requestTraceId
    }
  }

  const query = useQuery<
    { content: [], requestTraceId: string },
    AxiosError
  >(
    getQueryKey(),
    getFilters,
    {
      enabled: !!params,
      onError: (error) => {
        (error as AxiosError & { requestTraceId?: string }).requestTraceId = headers.requestTraceId
      }
    }
  )
  return query
}
