import enUS from './languages/en-ZA.json'
import enMX from './languages/es-419.json'
import koKR from './languages/ko-KR.json'
import ptBR from './languages/pt-BR.json'

export type Language = 'en-ZA' | 'es-419' | 'pt-BR' | 'ko-KR'

export const defaultNS = 'common'

export const resources = {
  'en-ZA': enUS,
  'es-419': enMX,
  'pt-BR': ptBR,
  'ko-KR': koKR
} as const
