import { DELETE_DEALS_URL_V2 } from '@constants'
import { MutationFunction, useMutation } from '@tanstack/react-query'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

type DeleteTableItemArgs = {
  ids: string[]
}

export const useDeleteTableItemV2 = () => {
  const auth = useAuthenticationService()
  const api = useApiService()
  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    email: auth.getUserEmailB2C().toLowerCase(),
    requestTraceId: generateUUID()
  }
  const deleteTableItem: MutationFunction<void, DeleteTableItemArgs> = async ({ ids }) => {
    const updatedHeaders = {
      ...headers
    }
    await api.delete({
      url: DELETE_DEALS_URL_V2,
      config: {
        headers: updatedHeaders,
        params: {
          ids: ids.join(',')
        }
      }
    })
  }
  return useMutation(deleteTableItem)
}
