import { t } from 'i18next'

export const paginationNormalizer = ({
  totalElements,
  currentPage,
  pageSize,
  onChange,
  showPageSizeSelector = true
}) => {
  return {
    current: currentPage + 1,
    onChange: typeof onChange === 'function' ? onChange : () => {},
    pageSize,
    pageSizeOptions: [10, 15, 20, 25],
    pageSizeOptionsIntl: (number: number) => `${t('deals.table.pageSizeOptions')} - ${number}`,
    quantityIndicatorIntl: t('deals.table.quantityIndicator'),
    showPageSizeSelector,
    showQuantityIndicator: true,
    total: totalElements ?? 0
  }
}
