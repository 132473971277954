import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false
    }
  }
})

export function ContainerDataProvider({ children }: PropsWithChildren<object>) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
