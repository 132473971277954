import { useAuthenticationService } from 'admin-portal-shared-services';
import { useTranslation } from 'react-i18next';

const useDynamicTranslations = () => {
    const { t } = useTranslation();
    const auth = useAuthenticationService()
    const country = auth.getCountryB2C()

    const translate = (namespace: string, key: string) => {
        const translationKey = `${namespace}.${key}`;
        return t(translationKey, key);
    };

    const dynamicTranslate = (contentItem: string, i18n_Reference?: string) => {
        const reference = i18n_Reference || 'deals.table.header'
        return translate(reference, contentItem);
    };

    const statusNormalizer = {
        AWAITING_ACCEPTANCE: 'awaiting_acceptance', 
        ENDED: 'ended',
        IN_PROGRESS: 'in_progress',
        NOT_ACCEPTED: 'not_accepted',
        PLANNED: 'planned'
    };

    const translateStatus = (status) => {
        const normalizedStatus = statusNormalizer[status] || status.toLowerCase();
        return translate('deals.table.status', normalizedStatus);
    };

    return { dynamicTranslate, translateStatus, country };
};

export default useDynamicTranslations;
