import { USER_GROUP_3P } from '@constants'
import { UserMetadata, useUserMetadata } from 'admin-portal-shared-services'
import { useMemo } from 'react'

export interface UseIs3pUser {
  is3pUser: boolean
  data: UserMetadata | undefined
  isLoading: boolean
  hasError: boolean
}

export const useIs3pUser = (): UseIs3pUser => {
  const { data, isLoading, hasError } = useUserMetadata()
  const userGroups = data?.authorization?.groups?.map((group) => group.toLowerCase())

  return useMemo<UseIs3pUser>(() => {
    const isDataLoaded = !isLoading && !hasError
    const isUser3pGroup = userGroups?.includes(USER_GROUP_3P.toLowerCase()) || false

    return {
      is3pUser: isDataLoaded && isUser3pGroup,
      data,
      isLoading,
      hasError
    }
  }, [isLoading, hasError])
}
