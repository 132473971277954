import { SidebarItem, useSidebar } from 'admin-portal-shared-services'
import { createElement, useEffect, useState } from 'react'
import { useSidebarService } from '../../service/sidebar'

export type THexaIcons = typeof import('@hexa-ui/icons')

export const renderIcon = (icons: THexaIcons, icon: string) => {
  const HexaIcon = icons[icon] ?? icons.Menu

  return () => createElement(HexaIcon)
}

export const useDynamicSidebar = () => {
  const [items, setItems] = useState<SidebarItem[]>([])
  const { data, isSuccess } = useSidebarService()

  useEffect(() => {
    if (isSuccess) {
      import('@hexa-ui/icons').then((icons) => {
        setItems(
          data.map((item) => ({
            ...item,
            icon: renderIcon(icons, item.icon)
          }))
        )
      })
    }
  }, [isSuccess, data])

  useSidebar({
    items,
    utils: []
  })
}
