import { CREATE_DEALS_URL } from '@constants'
import { useIs3pUser } from '@hooks'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

export const useCreateData = () => {
  const auth = useAuthenticationService()
  const api = useApiService()
  const { is3pUser } = useIs3pUser()

  const createDeals = async (idProcessing) => {
    const headers = {
      Authorization: auth.getAuthHeader(),
      country: auth.getCountryB2C(),
      requestTraceId: generateUUID(),
      email: auth.getUserEmailB2C().toLocaleLowerCase(),
      vendor: is3pUser ? 'LOREAL' : 'ABI'
    }

    await api.post({
      url: `${CREATE_DEALS_URL}/${idProcessing}`,
      data: '',
      config: {
        headers
      }
    })
  }

  return {
    createDeals
  }
}
