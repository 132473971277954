export * from './applicationBase'
export * from './enum'
export * from './localStorage'

export const DEALS_PAGENAME = 'DEALS'
export const DEFAULT_INITIAL_PAGE_SIZE = 10

export const BASE_URL = 'api/v1/link-deals'
export const DEALS_URL = `${BASE_URL}/deals`
export const DELETE_DEALS_URL_V2 = `${BASE_URL}/deals/v2/`
export const GET_DEALS_URL = `${BASE_URL}/deals/kam`
export const GET_DEALS_URL_V2 = `${BASE_URL}/deals/v2/kam`
export const GET_DEALS_URL_UNIFIED = `${BASE_URL}/deals/kam/v2`
export const UPLOAD_FILE = `${BASE_URL}/deals/upload/file`
export const CREATE_DEALS_URL = `${BASE_URL}/deals/create`
export const GET_DEALS_FILTER_URL = `${BASE_URL}/tracking/columnList/content`

export const GET_TABLE_DATA_BR_QUERY_KEY = 'GET_TABLE_DATA_BR_QUERY_KEY'
export const GET_TABLE_DATA_ZA_QUERY_KEY = 'GET_TABLE_DATA_ZA_QUERY_KEY'
export const GET_STORE_LIST_QUERY_KEY = 'GET_STORE_LIST_QUERY_KEY'
export const GET_DEALS_FILTER_QUERY_KEY = 'GET_DEALS_FILTER'
export const GET_DEAL_QUERY_KEY = 'GET_DEALS'

export const HELP_URL =
  'https://ab-inbev.atlassian.net/servicedesk/customer/portal/5/group/74/create/8859'
