import { useAuthenticationService } from 'admin-portal-shared-services'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { useEnvironment } from '../hooks'
import { IDelete, IGet, IPath, IPost, IPut } from './api.types'

export const interceptRegionUrl = (instance: AxiosInstance, zone: string) => {
  const zoneUrlMap: Record<string, string> = {
    BE: 'api/eu/',
    GB: 'api/eu/',
    ES: 'api/eu/',
    ID: 'api/eu/',
    NL: 'api/eu/',
    PH: 'api/eu/',
    AQ: 'api/eu/',
    TH: 'api/eu/'
  }

  const regionApi = zoneUrlMap[zone] || 'api/'

  instance.interceptors.request.use(
    (config) => {
      return { ...config, url: config.url?.replace('api/', regionApi) }
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export const setApiMethods = (axiosInstance: AxiosInstance) => {
  return {
    get: function get<T>(props: IGet): Promise<AxiosResponse<T>> {
      return axiosInstance.get<T>(props.url, props.config)
    },
    put: function put<T, D = unknown>(props: IPut<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.put<T>(props.url, props.data, props.config)
    },
    post: function post<T, D = unknown>(props: IPost<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.post<T>(props.url, props.data, props.config)
    },
    postFormData: function post<T, D = unknown>(props: IPost<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.post<T>(props.url, props.data, {
        ...props.config,
        headers: {
          ...props.config?.headers,
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    patch: function patch<T, D = unknown>(props: IPath<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.patch<T>(props.url, props.data, props.config)
    },
    delete: function deletes<T>(props: IDelete): Promise<AxiosResponse<T>> {
      return axiosInstance.delete<T>(props.url, props.config)
    }
  }
}

export const useApi = () => {
  const { baseUrl: baseURL } = useEnvironment()
  const auth = useAuthenticationService()
  const token = auth.getAuthHeader()

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: token
    }
  })

  interceptRegionUrl(axiosInstance, auth.getCountryB2C())

  const api = setApiMethods(axiosInstance)

  return api
}

export const useApiService = () => {
  const { serviceBaseUrl: baseURL } = useEnvironment()
  const auth = useAuthenticationService()
  const token = auth.getAuthHeader()

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: token
    }
  })

  interceptRegionUrl(axiosInstance, auth.getCountryB2C())

  const api = setApiMethods(axiosInstance)

  return api
}
