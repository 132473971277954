import { DealContent } from '@interfaces'
import { t } from 'i18next'

export type TTableItem = {
  chain: string
  product: string
  type: string
  ttvNow: number
  ttvWas: number | string
  ttcNow: number
  ttcWas: number
  isAcceptanceRequired: boolean
  startDate: string
  endDate: string
  status: string
  actions: string
  acceptedAt: string
  image: string
  qtdMax: string
  qtdMin: string
  vendorName: string
  potentialRefund: string | undefined
  refundPerDeal: string | undefined
  refundEarned: string | undefined
  deliveredQtd: string | undefined
  remainingQtd: string | undefined
  mechanic: string | undefined
  refund: string | undefined
  ean: string | undefined
  observations: string | undefined
  packaging: string
  measurement: string
  isCampaign: boolean
  achievement: string
  createdAt: string
  createdBy: string
  buyInStartDate: string
  buyInEndDate: string
  unitCount: string
  upc: string
  flag: string
  visibleStartDate?: string
  visibleEndDate?: string
  editSection: {
    limit: string
    type: string
    isEditable: boolean
    editedAt: string
    editedBy: string
  }
}

/* istanbul ignore next */
export const tableDataNormalizer = (data: DealContent[]) => {
  const result: TTableItem[] = data?.map((item) => ({
    chain: item.chainName,
    product: item.item.name,
    type: item.type,
    offerType: item.offerType,
    ttvNow: parseFloat(item.ttvNow),
    ttvWas: item.ttvWas ? parseFloat(item.ttvWas) : '-',
    ttcNow: parseFloat(item.ttcNow),
    ttcWas: parseFloat(item.ttcWas),
    startDate: item.from,
    endDate: item.to,
    status: item.status,
    actions: item.id,
    isAcceptanceRequired: item.isAcceptanceRequired,
    acceptedAt: item.acceptedAt,
    image: item.item.image,
    qtdMax: item.qtdMax,
    qtdMin: item.qtdMin,
    potentialRefund: item.refundEarned?.potential,
    refundPerDeal: item.refundEarned?.perDeal || item?.refund,
    refundEarned: item.refundEarned?.earned,
    deliveredQtd: item.quantity?.delivered,
    remainingQtd: item.quantity?.remaining,
    mechanic: item.mechanic,
    refund: item.refund,
    ean: item.item.id,
    observations: item.observations,
    vendorName: item.vendorName,
    packaging: item.item.packaging,
    measurement: `${item.item.unitCount}x${item.item.unitsPerCases} ${t('deals.table.drawer.dealDetails.measure')} • ${item.item.packaging || ''}`,
    isCampaign: item.campaignProduct,
    achievement: item.quantity?.achievement,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    buyInStartDate: item.buyInStartDate,
    buyInEndDate: item.buyInEndDate,
    unitCount: item.item.unitCount,
    upc: item.item.upc,
    flag: item.flag,
    visibleStartDate: item.visibleStartDate,
    visibleEndDate: item.visibleEndDate,
    editSection: {
      isEditable: item?.editSection?.isEditable,
      limit: item?.editSection?.limit,
      type: item?.editSection?.type,
      editedAt: item?.editSection?.editedAt,
      editedBy: item?.editSection?.editedBy
    }
  }))

  return result
}
