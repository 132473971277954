import { DEFAULT_INITIAL_PAGE_SIZE } from '@constants'
import { paginationNormalizer } from 'pages/Deals/List/DealsTable/shared/paginationNormalizer'
import { createContext, useCallback, useContext, useState } from 'react'

interface IDealsTableContext {
  acceptedTablePage: any
  availableTablePage: any
  expiredTablePage: any
  onChangePaginationAccepted: any
  onChangePaginationAvailable: any
  onChangePaginationExpired: any
  pageSizeAccepted: any
  pageSizeAvailable: any
  pageSizeExpired: any
  resetTablePages: () => void
  setAvailableTablePage: any
  setExpiredTablePage: any
  createPagination: (
    currentPage: number,
    pageSize: number,
    totalElements: number,
    onChange: any,
    showPageSizeSelector: boolean
  ) =>
    | {
        current: any
        onChange: any
        pageSize: number
        pageSizeOptions: number[]
        pageSizeOptionsIntl: (number: number) => string
        quantityIndicatorIntl: string
        showPageSizeSelector: boolean
        showQuantityIndicator: boolean
        total: any
      }
    | undefined
}

const DealsTablesContext = createContext({} as IDealsTableContext)

const DealsTablesProvider = ({ children }) => {
  const [availableTablePage, setAvailableTablePage] = useState(0)
  const [acceptedTablePage, setAcceptedTablePage] = useState(0)
  const [expiredTablePage, setExpiredTablePage] = useState(0)

  const [pageSizeAvailable, setPageSizeAvailable] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [pageSizeAccepted, setPageSizeAccepted] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [pageSizeExpired, setPageSizeExpired] = useState(DEFAULT_INITIAL_PAGE_SIZE)

  /* istanbul ignore next */
  const createPaginationHandler = (setPage, setPageSize) => (onChangedPage, onChangedPageSize) => {
    setPage(onChangedPage - 1)
    setPageSize(onChangedPageSize)
  }
  const onChangePaginationAvailable = createPaginationHandler(
    setAvailableTablePage,
    setPageSizeAvailable
  )
  const onChangePaginationAccepted = createPaginationHandler(
    setAcceptedTablePage,
    setPageSizeAccepted
  )
  const onChangePaginationExpired = createPaginationHandler(setExpiredTablePage, setPageSizeExpired)

  const createPagination = useCallback(
    (
      currentPage: number,
      pageSize: number,
      totalElements: number,
      onChange,
      showPageSizeSelector
    ) => {
      return paginationNormalizer({
        currentPage,
        totalElements,
        pageSize,
        onChange,
        showPageSizeSelector
      })
    },
    [paginationNormalizer]
  )

  const resetTablePages = () => {
    setAvailableTablePage(0)
    setExpiredTablePage(0)
    setAcceptedTablePage(0)
  }

  return (
    <DealsTablesContext.Provider
      value={{
        acceptedTablePage,
        availableTablePage,
        createPagination,
        expiredTablePage,
        onChangePaginationAccepted,
        onChangePaginationAvailable,
        onChangePaginationExpired,
        pageSizeAccepted,
        pageSizeAvailable,
        pageSizeExpired,
        resetTablePages,
        setAvailableTablePage,
        setExpiredTablePage
      }}
    >
      {children}
    </DealsTablesContext.Provider>
  )
}

const useDealsTables = () => {
  return useContext(DealsTablesContext)
}

export { DealsTablesContext, DealsTablesProvider, useDealsTables }
